<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :class="mergedClasses">
    <path
      fill="currentColor"
      d="M11.3 19.2998C11.1167 19.1165 11.0208 18.8831 11.0125 18.5998C11.0042 18.3165 11.0917 18.0831 11.275 17.8998L16.175 12.9998H5C4.71667 12.9998 4.47917 12.904 4.2875 12.7123C4.09583 12.5206 4 12.2831 4 11.9998C4 11.7165 4.09583 11.479 4.2875 11.2873C4.47917 11.0956 4.71667 10.9998 5 10.9998H16.175L11.275 6.0998C11.0917 5.91647 11.0042 5.68314 11.0125 5.3998C11.0208 5.11647 11.1167 4.88314 11.3 4.6998C11.4833 4.51647 11.7167 4.4248 12 4.4248C12.2833 4.4248 12.5167 4.51647 12.7 4.6998L19.3 11.2998C19.4 11.3831 19.4708 11.4873 19.5125 11.6123C19.5542 11.7373 19.575 11.8665 19.575 11.9998C19.575 12.1331 19.5542 12.2581 19.5125 12.3748C19.4708 12.4915 19.4 12.5998 19.3 12.6998L12.7 19.2998C12.5167 19.4831 12.2833 19.5748 12 19.5748C11.7167 19.5748 11.4833 19.4831 11.3 19.2998Z"
    />
  </svg>
</template>
